import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { mq } from '../styles/theme'
import Layout from '../components/Layout/layout'
import OrbImage from '../components/OrbImage';
import Form from '../components/Form/ResetPassword'
import ServerMessage from '../components/ServerMessage';
import Button from '../components/Button';
import { isAuthSSO } from '../services/auth';
import { navigate } from 'gatsby';

const Login = ({ userId, timestamp, token, pageContext }) => {
  const {
    PageTitle,
    PageSuccessMessage
  } = pageContext

  const [triggeredSuccess, setTriggeredSuccess] = useState(false)

  useEffect(() => {
    isAuthSSO() && navigate('/')
  }, [])

  return (
    <>
      {
        !isAuthSSO() &&
        <Layout>
          <FormBlock>
            <OrbElementTop>
              <OrbImage type={'nocolor-large'}/>
            </OrbElementTop>
            <OrbElementBottom>
              <OrbImage type={'nocolor-large'}/>
            </OrbElementBottom>
            {!triggeredSuccess && <FormContainer>
              <FormHeading>{PageTitle}</FormHeading>
              <FormWrapper>
                <Form setTriggeredSuccess={setTriggeredSuccess} userId={userId} timestamp={timestamp} token={token} pageTitle={PageTitle}/>
              </FormWrapper>
            </FormContainer>}

            {triggeredSuccess && <FormContainer>
              <PasswordUpdateHeading>Password updated</PasswordUpdateHeading>
              <FormInstructions>You can now use your new password to sign in to your account.</FormInstructions>
              {
                triggeredSuccess && <ServerMessage messagetype='success' triggered={triggeredSuccess} successText={PageSuccessMessage.processed}/>
              }
              <SignInButton text={'Sign In'} link={'/app/login'}/>
            </FormContainer>}

          </FormBlock>
        </Layout>
      }
    </>
  )
}

export default Login

export { Head } from '../components/_GatsbyPageHead'

const FormBlock = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  min-block-size: unset;
  height: calc(100vh - 60px);
  position: relative;
  overflow: hidden;

  ${mq.tablet} {
    min-block-size: 749px;
  }
`

const FormContainer = styled.div`
  margin-inline: 20px;
  max-inline-size: 475px;
  width: 100%;

  ${mq.tablet} {
    margin-inline: 200px;
  }

  ${mq.large} {
    margin-inline: 350px;
  }
`

const FormWrapper = styled.div`
  max-inline-size: 400px;
  margin: auto;
`

const FormHeading = styled.h2`
  font-family: "BuckeyeSerif";
  font-weight: 700;
  font-size: 28px;
  margin-block-end: 33px;
  text-align: center;

  ${mq.desktop} {
    font-size: 48px;
  }
`

const PasswordUpdateHeading = styled.h2`
  font-family: "BuckeyeSerif";
  font-weight: 700;
  font-size: 28px;
  margin-block-end: 20px;
  text-align: center;

  ${mq.desktop} {
    font-size: 48px;
  }
`

const FormInstructions = styled.p`
  margin-block-end: 20px;
`

const OrbElementTop = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: 500px;
  position: absolute;
  transform: translate(-50%,0%);

  ${mq.desktop} {
    display: block;
  }
`

const OrbElementBottom = styled.div`
  display: none;
  bottom: 0;
  right: 0;
  width: 600px;
  position: absolute;
  transform: translate(60%, 10%);

  ${mq.desktop} {
    display: block;
  }
`

const SignInButton = styled(Button)`
  margin-block-end: 30px;
  width: 100%;
  text-align: center;

  ${mq.desktop} {
    margin-block-end: 50px;
  }
`
