import React, { useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import FormController from './FormController'
import Button from '../Button'
import ServerMessage from '../ServerMessage'
import styled from 'styled-components'
import { mq } from '../../styles/theme'
import { handleResetPassword } from '../../services/auth'

function FormikWrapper({ userId, timestamp, token, setTriggeredSuccess }) {

  const [triggeredError, setTriggeredError] = useState(false)

  const initialValues = {
    password: '',
    passwordConfirmation: ''
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Please enter a password.')
      .min(8, 'Minimum of 8 characters required.')
      .test('valid', '3 out of 4 character sets required (Capital, Lowercase, Numbers, Special characters)', value => {
        let validConditions = 0;
        const hasUpperCase = /[A-Z]/.test(value);
        const hasLowerCase = /[a-z]/.test(value);
        const hasNumber = /[0-9]/.test(value);
        const hasSpecialCharacter = /[!@#%&]/.test(value);

        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter];
        conditions.forEach(condition =>
          condition ? validConditions++ : null
        );

        // Validate 3 out of 4 valid conditions
        if (validConditions >= 3) {
          return true;
        }
        return false;
      }),
    passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], "Passwords don't match").required('Please enter a password.')
  })

  if (typeof window !== "undefined") {
    const queryString = window.location.search;

    // Parse the query string
    const searchParams = new URLSearchParams(queryString);

    // Get a specific query parameter by name
    userId = searchParams.get('userid');
    timestamp = searchParams.get('timestamp');
    token = searchParams.get('token');
  }

  const onSubmit = values => {
    validationSchema
      .isValid(values)
      .then(async valid => {
        const res = await handleResetPassword(userId, timestamp, token, values.password)
        // eslint-disable-next-line no-undefined
        setTriggeredError(!res)
        setTriggeredSuccess(res)
      })
  }
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {formik => (
        <Form>
          {
            triggeredError &&  <ServerMessage messagetype='error' triggered={true} errorMessage='Incorrect username or password.'/>
          }
          <FormController
            control="input"
            type="password"
            label="New Password"
            name="password"
            onKeyUp={() => {
              formik.setErrors({ ...formik.errors, 'password': null })
              formik.setFieldTouched('password', false, false)
            }}
            error={ formik.errors.password }
          />
          <FormController
            control="input"
            type="password"
            label="Confirm Password"
            name="passwordConfirmation"
            onKeyUp={() => {
              formik.setErrors({ ...formik.errors, 'passwordConfirmation': null })
              formik.setFieldTouched('passwordConfirmation', false, false)
            }}
            error={ formik.errors.passwordConfirmation }
          />
          <FormButton isSubmit text="Reset Password" />
        </Form>
      )}
    </Formik>
  )
}
export default FormikWrapper

const FormButton = styled(Button)`
  margin-block-end: 30px;
  width: 100%;

  ${mq.desktop} {
    margin-block-end: 50px;
  }
`
